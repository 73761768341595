
import { defineComponent } from "vue";
import ConfirmCandidate from "@/views/ConfirmCandidate.vue";
import ValuesAndTraits from "@/views/AssessmentsTest/ValuesAndTraits.vue";
import AcceptableBehaviors from "@/views/AssessmentsTest/AcceptableBehaviors.vue";
import OaicArchetypes from "@/views/AssessmentsTest/OAICArchetypes.vue";
import WorkStyleIdentifier from "@/views/AssessmentsTest/WorkStyleIdentifier.vue";
import LogicTest from "@/views/AssessmentsTest/LogicTest.vue";
import HumanSkills from "@/views/AssessmentsTest/HumanSkills.vue";
import CustomSurveys from "@/views/AssessmentsTest/CustomSurveys.vue";
import _ from "lodash";
import helpers from "@/helpers/global";
import settings from "@/helpers/settings";
// import { ElLoading } from "element-plus";
// let loadingEl: any;

export default defineComponent({
  name: "AssessmentsContainerTest",
  // @deprecated
  /*
  title() {
    console.trace("AssessmentTest title");
    // @see vue-page-title v2.0.5
    // if (isFunction(title)) {
    //   this.$watch(
    //     () => title.call(this, this),
    //     (val) => {
    //       this.$setPageTitle(val);
    //     },
    //     { immediate: true }
    //   );
    //   return;
    // }
    let name = "";
    if (this.formTemplate) {
      let newName = this.formTemplate.form_name;
      if (this.formTemplate.form_name && newName.trim()) {
        name = newName.trim() + " | ";
      }
    }
    return `${name}My Culture`;
  },
  //*/
  components: {
    ConfirmCandidate,
    ValuesAndTraits,
    AcceptableBehaviors,
    OaicArchetypes,
    WorkStyleIdentifier,
    LogicTest,
    HumanSkills,
    CustomSurveys,
  },
  async created() {
    const code = this.$router.currentRoute.value.params.code || "";
    if (!code) {
      this.$router.replace(`/404`);
      return;
    }
    await this.$store.dispatch("routes/getRoute", code, { root: true });
    if (this.step == "get_start") {
      if (!this.initialized) {
        this.initialize();
      }
    }
    this.lang = (this as any).$i18n.getLocale(0);
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(value) {
      console.error(value);
    },
  },
  computed: {
    steps(): any[] {
      const sections = this.section || {};
      const steps = [];

      steps.push({
        name: "Step 0",
        order: steps.length + 1,
        title: "get_start",
        visible: false,
        desc: "",
      });

      // @fixed test without this step
      if (sections.values_and_traits) {
        steps.push({
          step: "values_and_traits",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("question.valueAndTraits.Values"),
          desc: "",
        });
      }
      if (sections.OAIC_archetypes) {
        steps.push({
          step: "OAIC_archetypes",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("main.Culture_Profile"),
          desc: "",
        });
      }
      if (sections.human_skills) {
        steps.push({
          step: "human_skills",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("main.Human_Skills"),
          desc: "",
        });
      }
      if (sections.acceptable_behaviors) {
        steps.push({
          step: "acceptable_behaviors",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("main.Acceptable_Behaviors"),
          desc: "",
        });
      }
      if (sections.work_style_identifier) {
        steps.push({
          step: "work_style_identifier",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("main.Work_Style_Identifier"),
          desc: "",
        });
      }
      if (sections.logic_test) {
        steps.push({
          step: "logic_test",
          name: helpers.t("Step_n", { step: steps.length }),
          order: steps.length + 1,
          title: helpers.t("main.Logic_Test"),
          desc: "",
        });
      }
      if (sections.custom_survey) {
        steps.push({
          step: "custom_survey",
          name: "Step " + steps.length,
          order: steps.length + 1,
          title: _.get(
            this.formTemplate,
            "custom_survey_title",
            helpers.t("main.Custom_Survey")
          ),
          desc: "",
        });
      }
      return steps;
    },
    currentStep(): any {
      // console.log("currentStep()");
      // console.log(this.steps, "this.steps");
      // console.log(this.step, "this.step");
      return _.find(this.steps, { step: this.step });
    },
    isFirstStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      return item.order == 1;
    },
    isLastStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order == this.steps.length;
    },
    // canPreviousStep(): boolean {
    //   return false;
    // },
    // canNextStep(): boolean {
    //   return false;
    // },
    companyLogoUrl(): any {
      const defaultLogoUrl = "";

      let hasTeam = false;
      let isPersonal = true;
      const team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
      }
      const useTeamLogo = hasTeam && !isPersonal;

      const companyLogoUrl = useTeamLogo
        ? this.$store.getters["team/companyLogoUrl"]
        : this.$store.getters["user/companyLogoUrl"];

      return companyLogoUrl || defaultLogoUrl;
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      const sections = formTemplate.sections || [];
      return extratime * sections.length;
    },
    lastSection() {
      const status =
        (!this.section.values_and_traits ||
          this.completeSection.values_and_traits) &&
        (!this.section.OAIC_archetypes ||
          this.completeSection.OAIC_archetypes) &&
        (!this.section.human_skills || this.completeSection.human_skills) &&
        (!this.section.acceptable_behaviors ||
          this.completeSection.acceptable_behaviors) &&
        (!this.section.work_style_identifier ||
          this.completeSection.work_style_identifier) &&
        (!this.section.logic_test || this.completeSection.logic_test) &&
        (!this.section.custom_survey || this.completeSection.custom_survey);
      if (status) {
        return true;
      }
      return false;
    },
    error(): any {
      return this.$store.getters["assessment/error"];
    },
    loading(): any {
      return this.$store.getters["assessment/loading"];
    },
    candidate(): any {
      return this.$store.getters["assessment/candidate"];
    },
    invitation(): any {
      return this.$store.getters["assessment/invitation"];
    },
    formTemplate(): any {
      return this.$store.getters["assessment/formTemplate"];
    },
    valuesAndTraits(): any {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      const formTemplate = this.$store.getters["assessment/formTemplate"];

      const isCustomFields = formTemplate
        ? formTemplate.values_and_traits_is_custom
        : false;

      const defaultQuestion = {
        checked: false,
        dirty: false,
        id: "",
        name: "",
        option: [
          { value: 1, label: "1" },
          { value: 2, label: "2" },
          { value: 3, label: "3" },
          { value: 4, label: "4" },
          { value: 5, label: "5" },
        ],
        order: 9999,
        title: "",
        title_th: "",
        type: "choice ",
        value: "",
      };

      const sortByName = (a: any, b: any) => {
        const nameA = a.title.toUpperCase(); // ignore upper and lowercase
        const nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      };

      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        if (isCustomFields) {
          const customFields = formTemplate.values_and_traits_custom_fields;
          const valuesIncludedOptions = customFields.values_included_options;

          const newQuestions = [];
          const questionsMap: any = {};
          for (const i in valuesAndTraits.scenarios.definingYourValues
            .questions) {
            const question =
              valuesAndTraits.scenarios.definingYourValues.questions[i];
            questionsMap[question.id] = question;
          }

          for (const i in valuesIncludedOptions) {
            const valuesIncludedOption = valuesIncludedOptions[i];
            const key = valuesIncludedOption.key;
            if (key.indexOf("custom_") === 0) {
              const customQuestion = {
                ...defaultQuestion,
                ...{
                  id: key,
                  name: valuesIncludedOption.labelEN,
                  title: valuesIncludedOption.labelEN,
                  title_th: valuesIncludedOption.labelTH,
                },
              };
              newQuestions.push(customQuestion);
            } else {
              if (Object.prototype.hasOwnProperty.call(questionsMap, key)) {
                newQuestions.push(questionsMap[key]);
              }
            }
          }
          valuesAndTraits.scenarios.definingYourValues.questions = newQuestions;
        }

        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );

        // @see https://trello.com/c/c3OAjDA8/1339-assessment-as-a-company-i-want-to-be-able-to-configure-my-values-traits-from-a-preset-list-so-that-i-can-easily-identify-items-t
        valuesAndTraits.scenarios.definingYourValues.questions.sort(sortByName);
      }

      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.identifyingKeyTraits &&
        valuesAndTraits.scenarios.identifyingKeyTraits.questions
      ) {
        if (isCustomFields) {
          const customFields = formTemplate.values_and_traits_custom_fields;
          const traitsIncludedOptions = customFields.traits_included_options;

          const newQuestions = [];
          const questionsMap: any = {};
          for (const i in valuesAndTraits.scenarios.identifyingKeyTraits
            .questions) {
            const question =
              valuesAndTraits.scenarios.identifyingKeyTraits.questions[i];
            questionsMap[question.id] = question;
          }

          for (const i in traitsIncludedOptions) {
            const traitsIncludedOption = traitsIncludedOptions[i];
            const key = traitsIncludedOption.key;
            if (key.indexOf("custom_") === 0) {
              const customQuestion = {
                ...defaultQuestion,
                ...{
                  id: key,
                  name: traitsIncludedOption.labelEN,
                  title: traitsIncludedOption.labelEN,
                  title_th: traitsIncludedOption.labelTH,
                },
              };
              newQuestions.push(customQuestion);
            } else {
              if (Object.prototype.hasOwnProperty.call(questionsMap, key)) {
                newQuestions.push(questionsMap[key]);
              }
            }
          }
          valuesAndTraits.scenarios.identifyingKeyTraits.questions =
            newQuestions;
        }

        valuesAndTraits.scenarios.identifyingKeyTraits.questions.sort(
          sortByName
        );
      }

      return valuesAndTraits;
    },
    greeting(): string {
      const candidate = this.$store.getters["assessment/candidate"];
      if (!candidate || !candidate.firstname) return "";
      return `${(this as any).$t("assessmentTest.Hello")} ${
        candidate.firstname
      },`;
    },
    creator(): string {
      return this.$store.getters["assessment/creator"];
    },
    timeSection(): number {
      const data = this.$store.getters["routes/data"];
      const sections = data.data.sections || data.data.section || [];
      const timeValue = sections.length;
      let timeSection = timeValue * 5;

      if (sections.indexOf("human_skills") !== -1) {
        timeSection += settings.HUMAN_SKILLS_TIME - 5;
      }
      if (sections.indexOf("OAIC_archetypes") !== -1) {
        timeSection += settings.CULTURE_PROFILE_TIME - 5;
      }
      if (sections.indexOf("logic_test") !== -1) {
        timeSection += settings.LOGIC_TEST_TIME - 5;
      }
      if (sections.indexOf("custom_survey") !== -1) {
        timeSection += settings.CUSTOM_SURVEY_TIME - 10;
      }

      return timeSection;
    },
    section(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const originalSection = formTemplate.sections || [];
      const hasSection = (name: string) => {
        return originalSection.indexOf(name) !== -1;
      };
      return {
        // @fixed
        values_and_traits: hasSection("values_and_traits"),
        OAIC_archetypes: hasSection("OAIC_archetypes"),
        human_skills: hasSection("human_skills"),
        acceptable_behaviors: hasSection("acceptable_behaviors"),
        work_style_identifier: hasSection("work_style_identifier"),
        logic_test: hasSection("logic_test"),
        custom_survey: hasSection("custom_survey"),
      };
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
    data(): any {
      return this.$store.getters["assessment/data"];
    },
  },
  data() {
    return {
      step: "confirm_candidate", // get_start, confirm_candidate, complete
      // values_and_traits, OAIC_archetypes, acceptable_behaviors, work_style_identifier, human_skills
      initialized: false,
      lang: "en",
      newAssessment: "",
      // section: {
      //   values_and_traits: true,
      //   OAIC_archetypes: true,
      //   acceptable_behaviors: true,
      // },
      // Negative with section!
      completeSection: {
        values_and_traits: false,
        OAIC_archetypes: false,
        human_skills: false,
        acceptable_behaviors: false,
        work_style_identifier: false,
        logic_test: false,
        custom_survey: false,
      } as any,
      publishSubmitStatus: true,
      hasPreviousStep: false,
      hasNextStep: false,
      canPreviousStep: false,
      canNextStep: false,
      doNextStep: false,
      doPreviousStep: false,
      count: 0,
      timerLabel: "0:00",
      timerPercentage: 0,
      showFloatingHeader: false,
    };
  },
  mounted() {
    // Add scroll listener
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove scroll listener
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    backToLastPage() {
      // console.log("backToLastPage()");
      // this.$router.push({ name: "Assessments" });
    },
    gotoPreviousStep() {
      console.log(
        `gotoPreviousStep(${this.doPreviousStep ? "true" : "false"})`
      );
      if (!this.canPreviousStep) return;
      // if (this.doPreviousStep) this.doPreviousStep = false;
      // It's pulse signal, dont care value
      this.doPreviousStep = true;
      this.count++;
      if (this.steps) return;

      if (!this.canPreviousStep) return false;

      const item: any = _.find(this.steps, { step: this.step });
      if (!item) return false;

      const order = item.order - 1;
      const previousItem: any = _.find(this.steps, { order: order });
      if (!previousItem) return false;

      this.step = previousItem.step;
    },
    async gotoNextStep() {
      // console.log(`gotoNextStep(${this.doNextStep ? "true" : "false"})`);
      if (!this.canNextStep) return;
      // if (this.doNextStep) this.doNextStep = false;
      this.doNextStep = true;
      // this.count++;
      // console.log(this.count, "this.count");
      if (this.steps) return;

      if (this.isLastStep) return;

      // await this.nextBTN_OAIC_dominant();
    },
    async publishSubmit() {
      // console.log("publishSubmit()");
      this.publishSubmitStatus = false;
      await this.gotoNextStep();
      // this.step = "complete";
      // delay for calculate quota before redirect to thank you page
      setTimeout(() => {
        this.finishAssessment();
        this.publishSubmitStatus = true;
      }, 5000);
    },
    gotoMainPage() {
      console.log("gotoMainPage()");
      this.$router.push("/");
    },
    noop() {
      //
    },
    activeClass() {
      return "active";
    },
    active() {
      //
    },
    tabNextStepCls(step: string) {
      let cls = "";
      const item = _.find(this.steps, { step: step });
      if (item && item.step == this.step) {
        cls = "active";
      } else {
        const currentItem = _.find(this.steps, { step: this.step });
        if (currentItem && item) {
          if (currentItem.order > item.order) {
            cls = "passed";
          }
        }
      }

      return cls;
    },
    gotoStep(step: any) {
      console.log(step, "step");
      return 1;
    },
    setCanPreviousStep(value: boolean) {
      // console.log(`setCanPreviousStep(${value ? 'true' : 'false'})`);
      // this.canPreviousStep = !value;
      this.canPreviousStep = value;
      if (this.canPreviousStep) {
        this.doPreviousStep = false;
      }
    },
    setCanNextStep(value: boolean) {
      // console.log(`setCanNextStep(${value ? 'true' : 'false'})`);
      // this.canNextStep = !value;
      this.canNextStep = value;
      if (this.canNextStep) {
        this.doNextStep = false;
      }
    },
    setDoNextStep(value: boolean) {
      // console.log(`setDoNextStep(${value ? 'true' : 'false'})`);
      this.doPreviousStep = value;
    },
    setDoPreviousStep(value: boolean) {
      // console.log(`setDoPreviousStep(${value ? 'true' : 'false'})`);
      this.doNextStep = value;
    },
    setHasPreviousStep(value: boolean) {
      this.hasPreviousStep = value;
    },
    setHasNextStep(value: boolean) {
      this.hasNextStep = value;
    },
    setTimerLabel(value: string) {
      this.timerLabel = value;
    },
    setTimerPercentage(value: number) {
      this.timerPercentage = value;
    },
    async initialize(data?: any) {
      // @see src\views\Assessments\AssessmentsDetails.vue
      try {
        const routeData = this.$store.getters["routes/data"] || {};
        const path = routeData.path || "";
        const isSecured = path == "/assessment-test/:id/:params";
        let id = "";
        let uuid = "";
        const parmas = new URLSearchParams();
        if (isSecured) {
          const data = routeData.data;
          id = routeData.uuid.split("-")[0];
          parmas.set("email", data.email);
          parmas.set("firstname", data.firstname);
          parmas.set("lastname", data.lastname);
          uuid = btoa(encodeURIComponent(parmas.toString()));
        } else {
          id = routeData.uuid;
          parmas.set("email", data.email);
          parmas.set("firstname", data.firstname);
          parmas.set("lastname", data.lastname);
          uuid = btoa(encodeURIComponent(parmas.toString()));
        }

        if (_.has(data, "lang")) {
          this.lang = _.get(data, "lang");
        }

        await this.$store.dispatch(
          "assessment/load",
          {
            id: id,
            uuid: uuid,
            isSecured: isSecured,
            lang: data.lang,
            sendFreeStuff: data.sendFreeStuff,
          },
          { root: true }
        );
        this.initialized = true;
      } catch (e) {
        console.error(e);
      }
    },
    reTurnValueAndTraits(values: { values_and_traits: any }) {
      // @todo save to invitation for observe progressing
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });

      this.completeSection.values_and_traits = true;
      this.nextStepBTN();
    },
    reTurnOAICArchetypes(values: { values_and_traits: any }) {
      // @todo save to invitation for observe progressing
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.OAIC_archetypes = true;
      this.nextStepBTN();
    },
    // reTurn -> return (typo style)
    reTurnHumanSkills(values: { human_skills: any }) {
      // console.log("reTurnHumanSkills()");
      // console.log(values, "values");
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.human_skills = true;
      this.nextStepBTN();
    },
    reTurnAcceptableBehaviors(values: { acceptable_behaviors: any }) {
      // console.log("reTurnAcceptableBehaviors()");
      // console.log(values, "values");
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.acceptable_behaviors = true;
      this.nextStepBTN();
    },
    reTurnWorkStyleIdentifier(values: any) {
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.work_sstyle_identifier = true;
      this.nextStepBTN();
    },
    returnLogicTest(values: { logic_test: any }) {
      // console.log("returnLogicTest()");
      // console.log(values, "values");
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });

      // @fixed test
      // if (this.completeSection) return;

      this.completeSection.logic_test = true;
      this.nextStepBTN();
    },
    reTurnCustomSurvey(values: { custom_survey: any }) {
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.custom_survey = true;
      this.nextStepBTN();
    },
    finishAssessment() {
      //*/
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          (this.lang == "th"
            ? "https://myculture.ai/th/thank-you-for-submitting"
            : "https://myculture.ai/thank-you-for-submitting");
        window.open(redirectUrl, "_self");
      }
      //*/
      // this.step = "complete";
    },
    finishConfirmCandidate(data: any) {
      if (data) {
        this.step = "get_start";
        if (!this.initialized) {
          this.initialize(data);
        }
      }
    },
    async nextStepBTN() {
      // console.log("nextStepBTN()");
      if (this.step == "get_start" && !this.data) return;
      const section = this.section;

      // Do not re-fill form if it's already filled
      if (this.data && this.step == "get_start") {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        if (hasOwnProperty.call(this.data, "section_times")) {
          const sectionTimes = this.data.section_times;
          if (_.isObject(sectionTimes)) {
            for (const sectionName in sectionTimes) {
              if (hasOwnProperty.call(sectionTimes, sectionName)) {
                this.completeSection[sectionName] = true;
              }
            }
          }
        }
      }

      const completeSection = this.completeSection;

      for (const key in section) {
        let selectedAssessment =
          section[key as keyof typeof section] === true &&
          completeSection[key as keyof typeof completeSection] === false;
        if (key == "values_and_traits" && selectedAssessment) {
          this.step = "values_and_traits";
          this.completeSection.values_and_traits = true;
          break;
        } else if (key == "OAIC_archetypes" && selectedAssessment) {
          this.step = "OAIC_archetypes";
          this.completeSection.OAIC_archetypes = true;
          break;
        } else if (key == "human_skills" && selectedAssessment) {
          this.step = "human_skills";
          this.completeSection.human_skills = true;
          break;
        } else if (key == "acceptable_behaviors" && selectedAssessment) {
          this.step = "acceptable_behaviors";
          this.completeSection.acceptable_behaviors = true;
          break;
        } else if (key == "work_style_identifier" && selectedAssessment) {
          this.step = "work_style_identifier";
          this.completeSection.work_style_identifier = true;
          break;
        } else if (key == "logic_test" && selectedAssessment) {
          this.step = "logic_test";
          this.completeSection.logic_test = true;
          break;
        } else if (key == "custom_survey" && selectedAssessment) {
          this.step = "custom_survey";
          this.completeSection.custom_survey = true;
          break;
        }
      }

      // mark status from invited to todo
      const routeData = this.$store.getters["routes/data"];
      const path = routeData.path || "";
      const isSecured = path == "/assessment-test/:id/:params";
      let uuid = routeData.uuid;
      if (!isSecured) {
        uuid = this.$store.getters["assessmentTemplate/publicInvitedUuid"];
      }
      const formDetails = {
        _id: uuid,
      };
      await this.$store.dispatch("assessment/start", formDetails, {
        root: true,
      });
    },
    handleScroll() {
      // Only show floating header on desktop
      if (window.innerWidth <= 1024) return;

      // Show/hide based on scroll position
      this.showFloatingHeader = window.scrollY > 100;
    },
  },
});
